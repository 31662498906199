import {
  Routes,
  Route,
  Navigate,
  HashRouter
  } from "react-router-dom";
import Evento from "../page/Eventos";
  import Login from '../page/Login';
import Inicio from "../page/Inicio";
import Codigos from "../page/Codigos";
import CambiarPassword from "../page/CambiarPassword"
import Pasarela from "../page/PasarelaPago";
import Prueba from "../page/prueba";
import Prueba1 from "../page/Prueba1";
import Pru from "../page/pru";
import CodigoView from "../page/CodigoView";
export default function App() {
    return (  
<HashRouter forceRefresh={true}>
<Routes>
        <Route exact path='/Login' element={<Login/>}/>
        <Route exact path='/Eventos' element={<Evento/>}/>
        <Route exact path='/Inicio/:idEvento' element={<Inicio/>}/>
        <Route exact path='/Inicio' element={<Inicio/>}/>
        <Route exact path='/Codigos/:idEvento' element={<Codigos/>}/>
        <Route exact path="/CambiarPassword" element={<CambiarPassword/>}/>
        <Route exact path="/Pago" element={<Pasarela/>}/>
        <Route exact path="/Prueba" element={<Prueba/>}/>
        <Route exact path="/Prueba1" element={<Prueba1/>}/>
        <Route exact path="/Pru" element={<Pru/>}/>
        <Route exact path="/:idEvento" element={<CodigoView/>}/>



        <Route path="*" element={<Navigate replace to="/Inicio"/>}/>
        </Routes>
      </HashRouter>
    );
  }