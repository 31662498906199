import React from 'react';

export default function Prueba1()
{
    return (<form method="POST" action="https://secure.micuentaweb.pe/vads-payment/">
    <input type="hidden" name="vads_action_mode" value="INTERACTIVE" />
    <input type="hidden" name="vads_amount" value="5000" />
    <input type="hidden" name="vads_ctx_mode" value="TEST" />
    <input type="hidden" name="vads_currency" value="604" />
    <input type="hidden" name="vads_cust_cell_phone" value="0606060606" />   
    <input type="hidden" name="vads_cust_email" value="my.email@example.com" />
    <input type="hidden" name="vads_cust_first_name" value="Pedro" />
    <input type="hidden" name="vads_cust_last_name" value="Garcia" />
    <input type="hidden" name="vads_cust_phone" value="0505050505" />
    <input type="hidden" name="vads_nb_products" value="1" />
    <input type="hidden" name="vads_order_info" value="Compra de entradas" />
    <input type="hidden" name="vads_page_action" value="PAYMENT" />
    <input type="hidden" name="vads_payment_config" value="SINGLE" />
    <input type="hidden" name="vads_product_amount0" value="5000" />
    <input type="hidden" name="vads_product_ext_id0" value="00001" />
    <input type="hidden" name="vads_product_label0" value="ENTRADA CLUB" />
    <input type="hidden" name="vads_product_qty0" value="5" />
    <input type="hidden" name="vads_product_ref0" value="01" />
    <input type="hidden" name="vads_product_type0" value="ENTERTAINMENT" />
    <input type="hidden" name="vads_site_id" value="80974113" />
    <input type="hidden" name="vads_trans_date" value="20230403210000" />
    <input type="hidden" name="vads_trans_id" value="123460" />
    <input type="hidden" name="vads_version" value="V2" />
    <input type="hidden" name="signature" value="neY+JSIJ8PDj4bgpz+0MNcWlG7sU2dH1eKwVZHmPEn0="/>
    <input type="submit" name="pagar" value="Pagar"/>
    </form>);
}