import { useState } from "react";

export default function Notificacion(props){
    
    return(
    <div>
        {props.mensaje&&
        <div style={
        {
            width:"300px",
            height:"60px",
            backgroundColor:"red",
            color:"white",
            textAlign:"center",
            borderRadius:"10px",
            marginTop:"40px"
            
        }
    }><p>{props.mensaje}</p></div>}</div>)
}