import React,{ useMemo, useState } from "react"
import jwt_decode from "jwt-decode";

const Context= React.createContext({})
export function UserContextProvider({children}){
    const [jwt,setJWT]=useState(()=>window.sessionStorage.getItem("jwt"))
    const[userName,setUserName]=useState("LUDIG")

const value=useMemo(()=>{
userName
},[userName])

    return <Context.Provider value={{jwt,setJWT,userName,setUserName}}>
        {children}
    </Context.Provider>
}
export default Context;