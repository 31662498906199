import React from 'react';
import { useState, useEffect } from 'react'
import KRGlue from '@lyracom/embedded-form-glue'
import axios from "axios";

export default function Prueba(props) {
    const [message, setMessage] = useState('')

    useEffect(() => {
        async function setupPaymentForm() {
          const endpoint = 'https://api.micuentaweb.pe'
          const publicKey = '80974113:testpublickey_M72SlbFmVAMtZTtXBavK3PltKk2xWkI8noMJGTgsHJ31h'
          let formToken = 'DEMO-TOKEN-TO-BE-REPLACED'
    
          try {
            const res = await axios.post('https://apiprueba.xinticket.pe/Mesas/CrearCargo', {
              method: 'POST',
            })

            console.log("REESSS",res);
            formToken = await res.data
    
            const { KR } = await KRGlue.loadLibrary(
              endpoint,
              publicKey
            ) /* Load the remte library */
    
            await KR.setFormConfig({
              /* set the minimal configuration */
              formToken: formToken,
              'kr-post-url-success':'https://xinticket.pe',
              'kr-spa-mode':'true',
              'kr-language': 'es-PE' /* to update initialization parameter */
            })
            await KR.button.onClick(async salida=>{
                console.log("Al hacer clic",salida)
            })
            await KR.onBrandChanged(async brand=>{
                console.log("MArca",brand);
            })
            await KR.onFormValid(async que=>{
                console.log("q",que);
            })
            await KR.onFormReady(async espera=>{
                console.log("Ahora si esta listo!!",espera);
            })

            await KR.onSubmit(async paymentData => {
                console.log("paymentData",paymentData);
              const response = await fetch(
                'http://localhost:3000/validatePayment',
                {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(paymentData)
                }
              )
              if (response.status === 200) {

                setMessage('Pago Correcto')}
              return false // Return false to prevent the redirection
            })
            const { result } = await KR.attachForm(
              '#myPaymentForm'
            ) /* Attach a payment form  to myPaymentForm div*/
    
            await KR.showForm(result.formId) /* show the payment form */
          } catch (error) {
            setMessage(error + ' (see console for more details)')
          }
        }
    
        setupPaymentForm()
      }, [])


  return (
    <div className="form">
        <div id="myPaymentForm">
          <div className="kr-embedded"  kr-popin="true">     <button className="kr-payment-button"></button>
</div>
        </div>
        <div data-test="payment-message">{message}</div>
    </div>
  );
}