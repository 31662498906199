import { useState,useEffect } from "react";
import  "../css/Login.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useNavigate } from "react-router-dom";

import md5 from "md5";
import useUser from "../Hooks/useUser";

export default function Login(){
    const {isLogged,login,isLoading,UserName}=useUser();

    const navigate=useNavigate()
    useEffect(()=>{
      if(isLogged)     navigate("/Eventos");

      
    },[isLogged])



    const [user,setUser]=useState({Username:"",Password:""});
    const handleSubmit=(e)=>{
        e.preventDefault();
        const nuevoUser={...user,["Password"]:md5(user.Password)}
        login(nuevoUser)

        if(!isLogged)
        {
            navigate("/Eventos")
        }
        }
    const handleChange= (e)=>{
        setUser({
                ...user,
                [e.target.name]:e.target.value
            });
    }
        return(
            <div className="containerPrincipal">
                {isLoading?
                <div><p>Cargando!!</p></div>:
                <div className="containerSecundario">
                    <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Usuario: </label>
                        <br/>
                        <input
                        name="Username"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        />
                        <br/>
                        <label>Contraseña: </label>
                        <br/>
                        <input
                        type="password"
                        name="Password"
                        onChange={handleChange}
                        className="form-control"
                        />
                        <br/>
                        <button className="btn btn-primary">Iniciar Sesión</button>
                    </div>
                    </form>
                </div>}
            </div>
        );

};
