import { useCallback, useEffect, useRef, useState } from "react";
import  "../css/eventos.css"
import "../css/Principal.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../Hooks/useUser";
import { conexionAPI_PostToken } from "../api/consultaAPI";
import Moment from "moment";

export default function Evento()
{
    const {search}=useLocation();
    const {isLogged,logout}=useUser();
    const [eventos,setEventos]=useState(null);
    const [eventoSelect, setEventoSelect]=useState(null);
    const [preciosEvento,setPreciosEvento]=useState(null);
    const [loading, setLoading]=useState(false);
    const navigate=useNavigate()
    const [cambio,setCambio]=useState(null);
    const [xPagar,setxPagar]=useState(0);
    const [Codigos,setCodigos]=useState([])
    const Ref=useRef();
    const SelectEvento=useCallback(async(id)=>{
        setLoading(true);
        if(id)
        conexionAPI_PostToken("EntradasEvento?id="+id,id).then(p => {
        if (p.correcto) {
           setPreciosEvento(p.datos);
           setEventoSelect(eventos[eventos.findIndex(p=>p.Id==id)])
        }
        else {
            setPreciosEvento([]);
        } 
    }).catch(e => { setPreciosEvento([]);})
    else
        setPreciosEvento(null)
    setLoading(false)})
    

    const CallBackAgregar=useCallback((item)=>{
        var index = preciosEvento.findIndex(p=>p.Id==item.Id);
        var obj= preciosEvento;
        if(obj[index].MaxEntradas<=0)
        {
            alert("No puede registrar entradas "+obj[index].Descripcion+". Se superó el máximo asignado.");
        }
        else
        {
        obj[index].Seleccionados=preciosEvento[index].Seleccionados+1;
        obj[index].MaxEntradas=obj[index].MaxEntradas-1;
        setPreciosEvento(obj);
        }
        setCambio(Math.random())
    })

    const CallBackQuitar=useCallback((item)=>{
        var index = preciosEvento.findIndex(p=>p.Id==item.Id);
        var obj=preciosEvento;
        if(preciosEvento[index].Seleccionados>0){

            obj[index].Seleccionados=preciosEvento[index].Seleccionados-1;
            obj[index].MaxEntradas=obj[index].MaxEntradas+1;
        }
        setPreciosEvento(obj)
        setCambio(Math.random())

    })

    const CallBackGenerarTickets=useCallback(async()=>{
        if(confirm("¿Seguro que desea continuar con la generación de códigos para las entradas seleccionadas?"))
        {
            setLoading(true);
        conexionAPI_PostToken("GenerarCodigos",preciosEvento).then(p=>{
            setCodigos(p.datos);
            setLoading(false);
            //SelectEvento(eventoSelect.Id)
        }).catch(p=>{
            alert("Error al Generar los códigos."+p.Mensaje);
            setLoading(false);
        })
    }
    })

    function Imagen(NombreArchivo)
{
    return NombreArchivo;
}


    useEffect(()=>{
      if(!isLogged)
      navigate("/Login");
    },[isLogged])

    useEffect(()=>{
        setLoading(true);
        if(new URLSearchParams(search).get("id"))
        {
            SelectEvento(new URLSearchParams(search).get("id"));
        }
        else
        {
            async function Leer(){
                await conexionAPI_PostToken("ListarEventos","fff").then(p => {
                    if (p.correcto) {
                       setEventos(p.datos);
                       SelectEvento(null)
                    }
                    else {
                        SelectEvento(null)
                        setEventos([]);
                    } 
                }).catch(e => {SelectEvento(null); setEventos([]);})
            } 
             Leer()            
        }
        setLoading(false)
    },[search])   

useEffect(()=>{
    if(preciosEvento!=null)
    {

    ElementoPrecioEvento()

    let cuenta=0;

    preciosEvento.map(p=>{
        cuenta+=(p.Precio*p.Seleccionados)
    })
    setxPagar(cuenta);

    }
    else
    {
        setxPagar(0)
    }
},[cambio])

const handleSubmit=(e)=>{
    logout();
    }

return (<><div className="Cabecera position-fixed">
    {eventoSelect&&<button type="button" onClick={()=>{navigate("/Codigos/"+eventoSelect.Id)}} className="btn btn-link link-light">Codigos</button>}
    <button type="button" onClick={handleSubmit} className="btn btn-link link-light">Cerrar Sesión</button>
</div>  {Codigos.length==0?
<div className="d-flex justify-content-center">
            <div style={{marginBottom:"50px"}} className="container text-center mt-5 justify-content-center " >
                {eventoSelect&&<div><h1>{eventoSelect.Nombre}</h1><h2>{eventoSelect.Ubicacion}</h2><h3>{Moment(eventoSelect.FechaEvento).format("DD/MM/yyyy")}</h3>{Moment(eventoSelect.FechaEvento).add(1,'d').isSameOrBefore(Moment())&&<h1 className="text-danger"   >EVENTO  VENCIDO</h1>}</div>}
                {
                    loading?<div></div>:preciosEvento?
                                        <ElementoPrecioEvento/>
                                        :eventos
                                        &&<ElementoEvento/>
                    }
            </div>
            {(!loading)&&eventoSelect&&<div ref={()=>Ref} onClick={()=>CallBackGenerarTickets()} className="footer d-flex justify-content-center" style={{backgroundColor:"#E04F5F",borderRadius:"10px",margin:"10px" }}>
                <div><h3 style={{color:"white"}}>Por pagar S/ {xPagar.toFixed(2)}</h3>
                </div>
            </div>}
</div>:<div style={{marginBottom:"50px"}} className="container text-center mt-5 justify-content-center">
    <h1>Códigos Generados:</h1><br/><br/>{Codigos.map(p=><h2>{p}</h2>)}	<a href={"https://api.whatsapp.com/send?text="+Codigos.toString().split(",").join("%0d%0a")}>Compartir en WhatsApp</a><br/><br/>
    <button className="btn btn-primary" onClick={()=>{window.location.reload(false)}}>Regresar</button>
</div>}</>)

function ElementoPrecioEvento() 
{
    return  (
        <div className="row mt-4" style={{marginBottom:"50px"}}>{
        preciosEvento.map(p=>
       <div className="col-sm-12 col-md-5 col-lg-3 py-3">
            <div className="card shadow">
                <div className="card-body">
                    <h3 className="card-title">{p.Descripcion}</h3>
                    <h5 className="card-text">S/ {p.Precio.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
                </div>
                <div className="card-footer text-muted justify-content-center" >
                    <div className="d-flex flex-row justify-content-evenly">
                    <div className="" >
                    <img src={require("../../assets/remove.svg")} width={45} height={45}  alt="Remove" onClick={()=>Moment(eventoSelect.FechaEvento).add(1,'d').isSameOrAfter(Moment())&&CallBackQuitar(p)}/>
                    </div>
                    <div className="" >
                    <h2>{p.Seleccionados}</h2>
                    </div>
                    <div className="" >
                    <img src={require("../../assets/add.svg")} width={45} height={45} alt="Add" onClick={()=>Moment(eventoSelect.FechaEvento).add(1,'d').isSameOrAfter(Moment())&&CallBackAgregar(p)}/>
                    </div>
                       </div>
                       <h5>Saldo Entradas: {p.MaxEntradas}</h5></div>
            </div>
        </div>)}</div>
    )
}

function ElementoEvento()
{
    return(
        <div className="row mt-4" style={{marginBottom:"50px"}}>
        {eventos.map(p=>
            <div key={p.Id} className="col-12 col-md-5 col-lg-3 py-3" style={{position:"relative"}}>
            <div className="card shadow"  onClick={()=>{SelectEvento(p.Id); /*navigate("/Eventos?id="+item.item.Id)*/}}>
                <img src={Imagen(p.ImagenFondo)} className="card-img-left hover-zoom"/>
                <div className="card-body">
                    <h5 className="card-title">{p.Nombre}</h5>
                    <p className="card-text">{p.Ubicacion}</p>
                </div>  
            </div>
            {Moment(p.FechaEvento).add(1,'d').isSameOrBefore(Moment())&&<img src={require("../../assets/Vencido.png")} onClick={()=>{SelectEvento(p.Id);}} style={{position:"absolute",zIndex:100,top:0,left:0,right:0,bottom:0, margin:"auto"}}/>}
        </div>)}</div>
    )
}



}