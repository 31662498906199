import Routes from "./src/routes/Routes"
import "./src/css/Principal.css"
import Notificacion from "./src/componentes/Notificacion";

import  {UserContextProvider} from "./src/Context/UserContext.js"

export default function App() {

  return (
    <UserContextProvider>
      <link rel="stylesheet"
href="https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/neon-reset.css"/>
<script
    src="https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/neon.js">
</script>
    <div>
      <div className="Notificacion position-fixed float-end">
      <Notificacion className="MensajeError" mensaje=""></Notificacion>
      </div><div>
      <Routes/>
      </div>
    </div>
    </UserContextProvider>
  );
}