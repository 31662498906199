import ImgToBase64 from 'react-native-image-base64';
import html2canvas from 'html2canvas';
import React, { useEffect, useState} from "react";
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,Dimensions,TextInput,  ImageBackground
} from 'react-native';
import LoadingTransparente from "./LoadingTransparente";
import {useParams } from "react-router-dom";
import QRCode from "react-native-qrcode-svg";
import moment from "moment";
import {size} from "lodash";
import { Input, Button } from "react-native-elements";
import * as DocumentPicker from 'expo-document-picker';
import {  consultasAPI_Post1,ObtenerDatosPersona } from "../api/consultaAPI";


export default function Pru(props) {
    const { width } = Dimensions.get("window");
    const [text, setText] = useState("");
    const [dni,setDNI]=useState("");
    const [mensaje,setMensaje]=useState("");
    const [ticket, setTicket]=useState({Qr:""});
    const [registrar, setRegistrar]=useState(false);
    const [emergente, setEmergente]=useState(false);
    const [imagenFondo,setImagenFondo]=useState("");
    const [imagenPrincipal,setImagenPrincipal]=useState("");
  const [loading, setLoading]=useState(false);
  const [formData, setFormData] = useState({Nombres:"",
  ApellidoPaterno:"",
  ApellidoMaterno:"",
  Celular:"",
  Correo:"",
  FechaNacimiento:"", NumDoc:"",Archivo:"",NombreArchivo:""});
  let { idEvento } = useParams();

  
    useEffect(()=>{
        if(typeof idEvento!='undefined')
        consultasAPI_Post1("Arena/VerificarUUID?UUID="+idEvento+"&emp=COCOA")
            .then(async p=>{
              setMensaje("");
              console.log("codigo", p.data.Evento.EventoPrecio.Descripcion);
              if(p.correcto)
              {
              if(p.data.Id!==0)
              {
                if(new Date(p.data.FechaCheckIn)>moment("01/01/2020","DD/MM/YYYY"))
                {
                  consultasAPI_Post1("Arena/ObtenerQR?uiid="+idEvento)
                  .then(pp=>{
                    if(pp.correcto)
                    {
                      p.data.Qr=pp.data; 
                      setTicket(p.data);
                      AbrirImagen(p.data.Evento.EventoPrecio.ImagenFondo)
                    }
                    else
                    {
                      setTicket(p.data);
                      setEmergente(true);
                      setMensaje(pp.Mensaje);
                    }
                    setLoading(false);
                    
                  })
                  .catch(p=>{
                    setTicket({Qr:""});
                    setLoading(false);
                    setEmergente(true);
                      setMensaje(p);
                  })
                }
                else
                {
                  setTicket(p.data);
                  AbrirImagen(p.data.Evento.ImagenFondo);
                  setLoading(false);
                  setRegistrar(true); 
                }
              }
              else
              {
                setLoading(false);
                setEmergente(true);
                setTicket({Qr:""});
                setMensaje("El código ingresado no existe.")
              }
              }
              else{
                setLoading(false);
                setTicket({Qr:""});
                setEmergente(true);
                setMensaje(p.Mensaje)
              }
            })
            .catch(ppp=>{
              setTicket({Qr:""});
              setLoading(false);
              setEmergente(true);
              setMensaje("Error al buscar.");
            })
      
      },[idEvento])

      useEffect(()=>{
        if(ticket.Qr!=="")
        {
            console.log("tivket",ticket)
            captureWebPage();
        }
      },[ticket])
      function AbrirImagen(NombreArchivo)
{
  const aws = require('aws-sdk');
  try{
    aws.config.setPromisesDependency(); //use so you can promisify to get the actual images
    aws.config.update({
        accessKeyId: 'AKIAYCD5WCQZ46EV5PH2',
        secretAccessKey: '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',
        region: 'us-east-2'
    });
    const s3 = new aws.S3();
    var params = {Bucket: 'xinticket', Key: NombreArchivo};
    var promise = s3.getSignedUrlPromise('getObject', params);
    promise.then(function(url) {
      setImagenFondo(url);
}, function(err) { throw "No Existe Imagen registrada" });



} catch(e) {
    throw "No Existe Imagen registrada";
}
}

    function captureWebPage() {
        const elementToCapture = document.getElementById('capture'); // Reemplaza 'elementId' con el ID del elemento que deseas capturar
        console.log("elemento",elementToCapture)
        if (elementToCapture) {
          html2canvas(elementToCapture,{allowTaint: true,useCORS: true,imageTimeout: 5000,logging:true}).then((canvas) => {
            // 'canvas' contiene la imagen capturada
            const image = new Image();
            image.src = canvas.toDataURL('image/jpg');
            console.log("direcc",canvas);
            console.log("ddd",image)
            // Agrega la imagen al DOM o guarda la imagen en tu servidor
            document.body.appendChild(image);
          });
        }
      }
    
    return(
        <View style={styles.container}>
      <div id="capture">
      <ImageBackground  
        style={{width:width>600?600:width,height:width>600?(600/0.56):width/0.56,justifyContent: "center"}}
        imageStyle={{width: "100%", height:"100%"}} 
        source={imagenFondo} >
      <View
        style={{
          alignItems: "center",
          backgroundColor:"#fff"
        }}
      >
      </View>
          <View>
          {ticket.Qr!==""&&<View style={{marginTop:"-250px", backgroundColor:"#fff", marginHorizontal:"auto",paddingHorizontal:30}}>
            <View style={{backgroundColor:"#fff",padding:20,marginHorizontal:"auto"}}>
            <QRCode
              value={ticket.Qr}
              size={180}/></View>
              <View style={{marginHorizontal:"auto",alignContent:"center"}}>
                <Text style={{textAlign:"center",color:"#000",fontSize:13,fontFamily:"Verdana"}}>{ticket.Cliente.ApellidoPaterno+" "+ticket.Cliente.ApellidoMaterno+", "+ticket.Cliente.Nombres}</Text>
                <Text style={{textAlign:"center", color:"#000",fontSize:13,fontFamily:"Verdana"}}>Documento: {ticket.Cliente.NumDoc}</Text>
                </View>
                </View>}
              </View>
              </ImageBackground>
      </div>
      <StatusBar style="auto" />
      <LoadingTransparente isVisible={loading} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
