import { useCallback, useContext, useState } from "react";
import { conexionAPI_Post } from "../api/consultaAPI";
import Context from "../Context/UserContext";
export default function useUser() {
    const { jwt, setJWT } = useContext(Context);
const [isLoading,setIsLoading]=useState(false);
    const login = useCallback(async (body) => {
        setIsLoading(true);
        await conexionAPI_Post("api/Login", body).then(p => {
            alert(p.Mensaje);
            if (p.correcto) {
                window.sessionStorage.setItem("jwt", p.datos);
                setJWT({...jwt,jwt:p.datos,Nombre:"Este Nombre"});

            }
            else {
                window.sessionStorage.removeItem("jwt");
                setJWT(null);
            }
            setIsLoading(false);

        }).catch(e => { setIsLoading(false); setJWT(null); window.sessionStorage.removeItem("jwt"); })
    }, [setJWT])

    const CambiarContrasena = useCallback(async (body) => {
        setIsLoading(true);
        await conexionAPI_Post("api/Login", body).then(p => {
            alert(p.Mensaje);
            if (p.correcto) {
                window.sessionStorage.setItem("jwt", p.datos);
                setJWT({...jwt,jwt:p.datos,Nombre:"Este Nombre"});

            }
            else {
                window.sessionStorage.removeItem("jwt");
                setJWT(null);
            }
            setIsLoading(false);

        }).catch(e => { setIsLoading(false); setJWT(null); window.sessionStorage.removeItem("jwt"); })
    }, [setJWT])


    const logout = useCallback(() => {
        window.sessionStorage.removeItem("jwt");
        setJWT(null)
    }, [setJWT])
    return {
        isLogged: Boolean(jwt),
        login,
        logout,
        isLoading:isLoading,
        UserName:JSON.stringify(jwt)
    }
}