import axios from "axios";

const DIRECCION_API='https://api.xinticket.pe/'
export async function conexionAPI_Post(direccion, body) {
    return await axios.post(DIRECCION_API + direccion, body,{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',

        }})
      .then(function (json) {
        return json.data;
      })
      .catch(function (error) {
        throw "Error en el inicio de Sesion desde API: " +error;
      });
  }


  export async function conexionAPI_PostToken(direccion, body) {
      return await axios.post(DIRECCION_API + direccion, body,{
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+window.sessionStorage.getItem("jwt")
          }})
        .then(function (json) {
          return json.data;
        })
        .catch(function (error) {
          throw "Error en el inicio de Sesion desde API: " +error;
        });
    }


    export async function conexionAPI_PostToken1(direccion, body) {
        return await axios.post(DIRECCION_API + direccion+"?id="+body, body,{
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json',
              'Authorization':'Bearer '+window.sessionStorage.getItem("jwt")
            }})
          .then(function (json) {
            return json.data;
          })
          .catch(function (error) {
            throw "Error en el inicio de Sesion desde API: " +error;
          });
      }

      export async function consultasAPI_Post1(direccion,cliente) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: cliente,
        };
        return await fetch(DIRECCION_API+direccion, requestOptions)
        .then(status)
      .then(json)
        .then(function (json) {
          return json;
        })
        .catch(function (error) {
          throw error;
        });
      }

      function status(response) {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else throw response.status;
      }
      
      function json(response) {
        return response.json();
      }



  export function AbrirImagen(NombreArchivo)
  {
    const aws = require('aws-sdk');
    try{
      aws.config.setPromisesDependency(); //use so you can promisify to get the actual images
      aws.config.update({
          accessKeyId: 'AKIAYCD5WCQZ46EV5PH2',
          secretAccessKey: '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',
          region: 'us-east-2'
      });
      const s3 = new aws.S3();
      var params = {Bucket: 'xinticket', Key: NombreArchivo};
      var promise = s3.getSignedUrlPromise('getObject', params);
      promise.then(function(url) {
        return url;
}, function(err) { throw "No Existe Imagen registrada" });

  } catch(e) {
      throw "No Existe Imagen registrada";
  }
  }

  export async function  ObtenerDatosPersona(DNI)
  {

    var requestOptions = {
      method: "GET",
    };
    return await fetch("https://api.ludfact.com/Consulta/DNI?numDoc="+DNI, requestOptions)
      .then(status)
      .then(json)
      .then(function (json) {
        return json;
      })
      .catch(function (error) {
        throw error;
      });

  }
