import React, { useEffect, useState} from "react";
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View,Dimensions,TextInput,  ImageBackground
} from 'react-native';
import LoadingTransparente from "./LoadingTransparente";
import {useParams } from "react-router-dom";
import QRCode from "react-native-qrcode-svg";
import moment from "moment";
import {size} from "lodash";
import { Input, Button } from "react-native-elements";
import * as DocumentPicker from 'expo-document-picker';
import {  consultasAPI_Post1,ObtenerDatosPersona } from "../api/consultaAPI";

export default function Inicio() {
  const { width } = Dimensions.get("window");
  const [text, setText] = useState("");
  const [dni,setDNI]=useState("");
  const [mensaje,setMensaje]=useState("");
  const [ticket, setTicket]=useState({Qr:""});
  const [registrar, setRegistrar]=useState(false);
  const [emergente, setEmergente]=useState(false);
  const [imagenFondo,setImagenFondo]=useState("");
  const [imagenPrincipal,setImagenPrincipal]=useState("");
const [loading, setLoading]=useState(false);
const [formData, setFormData] = useState({Nombres:"",
ApellidoPaterno:"",
ApellidoMaterno:"",
Celular:"",
Correo:"",
FechaNacimiento:"", NumDoc:"",Archivo:"",NombreArchivo:""});
let { idEvento } = useParams();


const pickDocument = async () => {
  
  let result = await DocumentPicker.getDocumentAsync({});
  if(result.size/1024/1024>3){
    setMensaje("El archivo seleccionado debe ser menor a 3MB.");
    setFormData({ ...formData, ["Archivo"]: "" });
    setFormData({ ...formData, ["NombreArchivo"]: "" });  
  }
  else{
    setMensaje("");
  setFormData({ ...formData, ["Archivo"]: result.uri,["NombreArchivo"]: result.name });
}
};

function Limpiar()
{
  setTicket({Qr:""});
  setFormData({Nombres:"",
  ApellidoPaterno:"",
  ApellidoMaterno:"",
  Celular:"",
  Correo:"",
  FechaNacimiento:"", NumDoc:"",NombreArchivo:"",Archivo:""});
  setRegistrar(false);
  setMensaje("");
  setText("");
  setDNI("");
}
const onChange = (e, type) => {
  setFormData({ ...formData, [type]: e.nativeEvent.text });
};
function Registrar()
{
  setMensaje("");
  if(formData.Nombres==""||
  formData.ApellidoPaterno==""||
  formData.ApellidoMaterno==""||
  formData.Celular==""||
  formData.Correo==""||
  formData.FechaNacimiento==""|| formData.NumDoc=="")
  {
    setMensaje("Llene todos los campos");
    setLoading(false);
    return;
  }
  
  if(size(formData.NumDoc)<8)
  {
    setMensaje("El número de documento no es el válido.");
    setLoading(false);
    return;
  }
  if(!validateEmail(formData.Correo))
  {
    setMensaje("el formato del correo no es el correcto.");
    setLoading(false);
    return;
  }
  if(size(formData.Celular)!=9)
  {
    setMensaje("El número de celular no cuenta con los 9 dígitos.");
    setLoading(false);
    return;
  }
  if(!(moment(formData.FechaNacimiento,"DD/MM/YYYY",true).isValid()))
  {
    setMensaje("Ingrese una fecha de nacimiento válida.");
    setLoading(false);
    return;
  }

  let Cliente =formData;

  Cliente.FechaNacimiento=moment(Cliente.FechaNacimiento,"DD/MM/YYYY");
  consultasAPI_Post1("Arena/CheckIn?uiid="+text,JSON.stringify(Cliente))
  .then(p=>{
    if(p.correcto)
    {
      setTicket(p.data);
      AbrirImagen(p.data.Evento.EventoPrecio.ImagenFondo);
    setRegistrar(false);
      }
        else
      setMensaje(p.Mensaje);
    setLoading(false);
  })
  .catch(pp=>{
    setMensaje("Error al registrar.");
    setLoading(false);
  })

}
function AbrirImagen(NombreArchivo)
{
  const aws = require('aws-sdk');
  try{
    aws.config.setPromisesDependency(); //use so you can promisify to get the actual images
    aws.config.update({
        accessKeyId: 'AKIAYCD5WCQZ46EV5PH2',
        secretAccessKey: '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',
        region: 'us-east-2'
    });
    const s3 = new aws.S3();
    var params = {Bucket: 'xinticket', Key: NombreArchivo};
    var promise = s3.getSignedUrlPromise('getObject', params);
    promise.then(function(url) {
      setImagenFondo(url);
}, function(err) { throw "No Existe Imagen registrada" });



} catch(e) {
    throw "No Existe Imagen registrada";
}
}



function ImagenInicial(NombreArchivo)
{
  const aws = require('aws-sdk');
  try{
    aws.config.setPromisesDependency(); //use so you can promisify to get the actual images
    aws.config.update({
        accessKeyId: 'AKIAYCD5WCQZ46EV5PH2',
        secretAccessKey: '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',
        region: 'us-east-2'
    });
    const s3 = new aws.S3();
    var params = {Bucket: 'xinticket', Key: NombreArchivo};
    var promise = s3.getSignedUrlPromise('getObject', params);
    promise.then(function(url) {
      setImagenPrincipal(url);
}, function(err) { throw "No Existe Imagen registrada" });


} catch(e) {
    throw "No Existe Imagen registrada";
}
}


useEffect(()=>{
  
  ImagenInicial("fondo_COCOA2023.png")},[])

useEffect(()=>{
  if(typeof idEvento!='undefined')
  consultasAPI_Post1("Arena/VerificarUUID?UUID="+idEvento+"&emp=COCOA")
      .then(async p=>{
        setMensaje("");
        console.log("codigo", p.data.Evento.EventoPrecio.Descripcion);
        if(p.correcto)
        {
        if(p.data.Id!==0)
        {
          if(new Date(p.data.FechaCheckIn)>moment("01/01/2020","DD/MM/YYYY"))
          {
            consultasAPI_Post1("Arena/ObtenerQR?uiid="+idEvento)
            .then(pp=>{
              if(pp.correcto)
              {
                p.data.Qr=pp.data; 
                setTicket(p.data);
                AbrirImagen(p.data.Evento.EventoPrecio.ImagenFondo) 
              }
              else
              {
                setTicket(p.data);
                setEmergente(true);
                setMensaje(pp.Mensaje);
              }
              setLoading(false);
              
            })
            .catch(p=>{
              setTicket({Qr:""});
              setLoading(false);
              setEmergente(true);
                setMensaje(p);
            })
          }
          else
          {
            setTicket(p.data);
            AbrirImagen(p.data.Evento.ImagenFondo);
            setLoading(false);
            setRegistrar(true); 
          }
        }
        else
        {
          setLoading(false);
          setEmergente(true);
          setTicket({Qr:""});
          setMensaje("El código ingresado no existe.")
        }
        }
        else{
          setLoading(false);
          setTicket({Qr:""});
          setEmergente(true);
          setMensaje(p.Mensaje)
        }
      })
      .catch(ppp=>{
        setTicket({Qr:""});
        setLoading(false);
        setEmergente(true);
        setMensaje("Error al buscar.");
      })

},[idEvento])


  function VerificarCodigo()
  {
    setEmergente(false);
    if(text=="")
    {
      setLoading(false);
      setEmergente(true);
      setMensaje("Ingrese un Código para realizar la búsqueda.");
    }
    else
    {
      consultasAPI_Post1("Arena/VerificarUUID?UUID="+text+"&emp=COCOA")
      .then(async p=>{
        setMensaje("");
        console.log("codigo", p.data.Evento.EventoPrecio.Descripcion);
        if(p.correcto)
        {
        if(p.data.Id!==0)
        {
          if(new Date(p.data.FechaCheckIn)>moment("01/01/2020","DD/MM/YYYY"))
          {
            consultasAPI_Post1("Arena/ObtenerQR?uiid="+text)
            .then(pp=>{
              if(pp.correcto)
              {
                p.data.Qr=pp.data; 
                setTicket(p.data);
                AbrirImagen(p.data.Evento.EventoPrecio.ImagenFondo) 
              }
              else
              {
                setTicket(p.data);
                setEmergente(true);
                setMensaje(pp.Mensaje);
              }
              setLoading(false);
              
            })
            .catch(p=>{
              setTicket({Qr:""});
              setLoading(false);
              setEmergente(true);
                setMensaje(p);
            })
          }
          else
          {
            setTicket(p.data);
            AbrirImagen(p.data.Evento.ImagenFondo);
            setLoading(false);
            setRegistrar(true); 
          }
        }
        else
        {
          setLoading(false);
          setEmergente(true);
          setTicket({Qr:""});
          setMensaje("El código ingresado no existe.")
        }
        }
        else{
          setLoading(false);
          setTicket({Qr:""});
          setEmergente(true);
          setMensaje(p.Mensaje)
        }
      })
      .catch(ppp=>{
        setTicket({Qr:""});
        setLoading(false);
        setEmergente(true);
        setMensaje("Error al buscar.");
      })
    }
    }

    function validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  
  return (
    <View style={styles.container}>
      <View>
      <ImageBackground 
        style={{width:width>600?600:width,height:width>600?(600/0.56):width/0.56,justifyContent: "center"}}
        imageStyle={{width: ticket.Qr?(ticket.Qr!=""?"100%":"0%"):"0%", height:"100%"}} 
        source={ticket.Qr&&imagenFondo} >
      <View
        style={{
          alignItems: "center",
          backgroundColor:"#fff"
        }}
      >
      </View>
      {registrar?<ImageBackground style={{width:width>600?600:width,height:width>600?(600/0.56):width/0.56}} imageStyle={{width: "100%", height:"100%"}}
            source={imagenFondo}>
              <View style={{justifyContent: "center", flex:0.95,marginTop:"-50px",marginHorizontal:"10px"}}>
        <View style={{ marginTop:50, paddingVertical:20, marginHorizontal:20,backgroundColor:"rgba(255, 255, 255, 0.60)",borderRadius:10}}>
            <Input
            placeholder="DNI/CE"
            placeholderTextColor="#000"
            keyboardType="numeric"
            inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
            containerStyle={{marginHorizontal:"auto"}}
            onChange={(e) => onChange(e, "NumDoc")}
            blurOnSubmit={true}
            />
          <Input
          placeholderTextColor="#000"
          placeholder="Apellido Paterno"
        inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
        containerStyle={{marginHorizontal:"auto"}}
        onChange={(e) => onChange(e, "ApellidoPaterno")}
          />
          <Input
          placeholder="Apellido Materno"
          placeholderTextColor="#000"
        inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
        containerStyle={{marginHorizontal:"auto"}}
        onChange={(e) => onChange(e, "ApellidoMaterno")}
          />
          <Input
          placeholder="Nombres"
          placeholderTextColor="#000"
        inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
        containerStyle={{marginHorizontal:"auto"}}
        onChange={(e) => onChange(e, "Nombres")}
          />
          <Input
          placeholder="Fecha Nacimiento: dd/mm/yyyy"
          placeholderTextColor="#000"
        inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
        containerStyle={{marginHorizontal:"auto"}}
        onChange={(e) => onChange(e, "FechaNacimiento")}
          />
          <Input
          placeholder="E-mail"
          placeholderTextColor="#000"
        keyboardType="email-address"
        inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
        containerStyle={{marginHorizontal:"auto"}}
        onChange={(e) => onChange(e, "Correo")}
          />
          <Input
          placeholder="Celular"
          placeholderTextColor="#000"
          containerStyle={{marginHorizontal:"auto"}}
          inputStyle={{color:"#000",fontFamily:"Verdana", fontSize:18}}
          keyboardType="numeric"
        onChange={(e) => onChange(e, "Celular")}
          />
          <View style={{ justifyContent:"center", alignContent:"center",marginBottom:20}}>
          <Text style={{color:"red",fontFamily:"Verdana", fontSize:13, textAlign:"center"}}>{mensaje}</Text>
                </View>
                <View style={{width:250, marginHorizontal:"auto"}}>
                <Button   
                buttonStyle={{backgroundColor:"#c09748"}}
                  title="Registrar"
                  onPress={()=>{setLoading(true); Registrar()}}
                /></View>
          </View>
          </View></ImageBackground>:
          <View>
            {ticket.Qr==""&&
          <View>            
            <ImageBackground source={imagenPrincipal} style={{width:width>600?600:width,height:width>600?(600/0.56):width/0.56}} imageStyle={{width: "100%", height:"100%", justifyContent:"center"}}>
          <View style={{ marginTop:width>600?(600*0.5+200):((width*0.5+120)),  justifyContent:"center"}}><TextInput
          style={{textAlign:"center", paddingHorizontal:10,fontFamily:"Verdana",fontSize:20, marginBottom:10, marginHorizontal:"auto", backgroundColor:"#fff",width:250, height:50,alignContent:"center"}}
          autoCapitalize="characters"  
          placeholder="Ingresa tu código..."
            onChange={(e) => setText(e.nativeEvent.text)}
          />
          <View style={{ justifyContent:"center", alignContent:"center",marginBottom:10}}>
    <Text style={{color:"red",fontFamily:"Verdana",fontSize:15, textAlign:"center"}}>{mensaje}</Text>
          </View>
          <View style={{width:250, marginHorizontal:"auto", marginBottom:30}}>
          <Button
          buttonStyle={{backgroundColor:"#c09748"}}   
            title="Continuar"
            onPress={()=>{setLoading(true); VerificarCodigo()}}
          /></View></View></ImageBackground></View>}
          {ticket.Qr!==""&&<View style={{marginTop:"-250px", backgroundColor:"#fff", marginHorizontal:"auto",paddingHorizontal:30}}>
            <View style={{backgroundColor:"#fff",padding:20,marginHorizontal:"auto"}}>
            <QRCode
              value={ticket.Qr}
              size={180}/></View>
              <View style={{marginHorizontal:"auto",alignContent:"center"}}>
                <Text style={{textAlign:"center",color:"#000",fontSize:13,fontFamily:"Verdana"}}>{ticket.Cliente.ApellidoPaterno+" "+ticket.Cliente.ApellidoMaterno+", "+ticket.Cliente.Nombres}</Text>
                <Text style={{textAlign:"center", color:"#000",fontSize:13,fontFamily:"Verdana"}}>Documento: {ticket.Cliente.NumDoc}</Text>
                </View>
                </View>}
              </View>}
              </ImageBackground>
      </View>
      {ticket.Qr!==""&&<View style={{width:250, marginHorizontal:"auto",marginVertical:10}}>
          <Button   
          buttonStyle={{backgroundColor:"#c09748"}}
            title="Regresar"
            onPress={()=>{Limpiar()}}
          /></View>}
      <StatusBar style="auto" />
      <LoadingTransparente isVisible={loading} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex:1,
    backgroundColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

